import classNames from "classnames";
import * as React from "react";

import Carrousel from "../components/Carrousel";
import Image from "../components/Image";
import Chiffres from "../components/Chiffres";
import Text from "../components/Text";
import Title from "./Title";
import Citation from "./Citation";
import Testimonial from "./Testimonial";

const generateKey = (kind) => {
    return `${ kind }_${ new Date().getTime() }`;
};

function subChildElements(dynamic, fonts, article, updateMasonry) {

    if(dynamic) {

        return dynamic.map((content, index) => {
            switch (content.kind) {
                case 'title':
                    return (<Title key={'key-c-' + index} datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id)  } article={ article } />);
                case 'text':
                    return (<Text key={'key-c-' + index}  datas={ content }
                                  fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'citation':
                    return (<Citation key={'key-c-' + index}  datas={ content }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'testimonial':
                    return (<Testimonial key={'key-c-' + index}  datas={ content }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'slider':
                    return (<Carrousel key={'key-c-' + index}  datas={ content } updateMasonry={ updateMasonry }
                                       fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'images':
                    return (<Image key={'key-c-' + index}  datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'chiffres':
                    return (<Chiffres key={'key-c-' + index}  datas={ content } updateMasonry={ updateMasonry }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                default:
                    return null;
            }
        })
    }

    return null;

}

const Breve = ({ style, fonts, datas, article, updateMasonry }) => {

    const classes = classNames({
        'style': datas.content.is_encadre,
        'component': true,
        'component-breve': true
    });

    const style_breve = (datas.content.is_encadre) ? {
      borderColor: datas.couleur_encadre || article.category.couleur
    } : { };

    const style_title = {
      color: article.couleur || article.category.couleur,
      ...fonts.family1
    }
    
    return(
        <div className={ classes }>
            {article.custom.is_breves && datas.content.titre &&
                <h2 style={style_title} dangerouslySetInnerHTML={{ __html: datas.content.titre }} />
            }

            <div className="inner" style={ style_breve }>
                {!article.custom.is_breves && datas.content.titre &&
                    <h2 key={'key-s-title'} style={style_title} dangerouslySetInnerHTML={{ __html: datas.content.titre }} />
                }
                {datas.content.texte &&
                    <p key={'key-s-text'} style={fonts.family1} dangerouslySetInnerHTML={{ __html: datas.content.texte }} />
                }
                { subChildElements(datas.content.dynamic, fonts, article, updateMasonry) }
            </div>
        </div>
    )
};

export default Breve;

import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import NumeroView from './views/NumeroView';
import { Query } from "react-apollo";
import {
    GET_ALL, UPDATE_CURRENT_NUMERO, GET_CURRENT_NUMERO,
    GET_NUMERO_BY_SLUG
} from "../../../../Queries";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";

//import persist from "../../../../persist";

// class NumeroSwitch extends Component {
//
//     changeNumeroInLocalState(slug) {
//
//         this.props.client.writeQuery({
//             query: UPDATE_CURRENT_NUMERO_SWITCH,
//             data: {
//                 NumeroSwitch : {
//                     slug,
//                     __typename: "NumeroSwitch"
//                 }
//             }
//         });
//
//         const currentNumero =
//             this.props.client.readQuery({ query: GET_CURRENT_NUMERO_SWITCH });
//
//     }
//
//     componentDidUpdate() {
//     }
//
//     render() { let self = this;
//         return(
//             <Query query={ GET_CURRENT_NUMERO_SWITCH } fetchPolicy={ "cache-only" }>
//                 {({loading, error, data, updateQuery, client, networkStatus}) => {
//
//                     if(loading) return null;
//
//                     if(typeof data.NumeroSwitch !== "undefined"){
//                         if(data.NumeroSwitch.slug != null){
//                             return this.props.children
//                         } else {
//                             self.changeNumeroInLocalState(this.props.slug);
//                             return this.props.children
//                         }
//                     } else {
//                         self.changeNumeroInLocalState(this.props.slug);
//                         return this.props.children
//                     }
//                 }}
//             </Query>
//         );
//     }
//
// }

export class CurrentNumero extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentNumeroId: null
        }

    }

    /*static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.currentNumeroId !== prevState.currentNumeroId)
            return {
                currentNumeroId: nextProps.currentNumeroId
            };

        return null;
    }*/

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.currentNumero._id !== nextProps.currentNumero._id;
    }

    updateCurrentNumeroToStore() {

        let client = this.props.client;
        let cNum = this.props.currentNumero;

        client.writeQuery({
            query: UPDATE_CURRENT_NUMERO,
            data: {
                currentNumero : cNum
            }
        });

        client.writeQuery({
            query: GET_CURRENT_NUMERO,
            data: {
                currentNumero : cNum
            }
        });

        const dataQuery = this.props.client.readQuery({ query: GET_ALL, variables: {
                magazine_slug: this.props.magazine_slug, offset:0
            } });

        if(!dataQuery.datas.numeros.filter((numero) => numero._id === this.props.currentNumero._id)[0]){

            if(this.props.currentNumero) {
                if (this.props.currentNumero._id) {
                    dataQuery.datas.numeros.push(this.props.currentNumero);

                    try {
                        this.props.client.writeQuery({
                            query: GET_ALL,
                            variables: {
                                magazine_slug: this.props.magazine_slug,
                                offset: 0
                            },
                            data: {
                                datas: dataQuery.datas
                            }
                        });

                        const getCurrentNumero = this.props.client.readQuery({ query: GET_CURRENT_NUMERO });

                    } catch(e) {
                        console.log("error : ", e)
                    }
                }
            }

        }

    }

    componentWillMount() {
        this.updateCurrentNumeroToStore();
    }

    componentDidUpdate() {
        this.updateCurrentNumeroToStore();
    }

    render() {
        return null
    }
}

export class ComponentExemple extends Component {

    render() {
        return(
            <div className="componentExemple">
                <h1>Component Exemple</h1>
            </div>
        )
    }


}

class Numero extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            numeros: [],
            currentNumero: {},
            menuShow: false,
            loading: true
        };

    }

    getResetCache() {

        if(typeof this.context.resetCache === "undefined"){
            if(typeof window !== "undefined"){
                let parameters = window.location.search.substr(1);
                return !parameters.includes("cache=true");
            } else {
                return false;
            }
        } else {
            return this.context.resetCache;
        }

    }

    componentDidMount() { let self = this;

        setTimeout(function(){
            self.setState({
                loading: false
            });
            //self.props.toggleLoadingOff();
        }, 300)
    }

    getNumero(numeros, client) {
        return numeros.filter((numero) => numero.slug === this.props.match.params.id)[0];
    }

    getNumeroDatas(numeros, numero, client) {

        let numeroObj;
        let id;

        if(!numero){
            numeroObj = this.getNumero(numeros, client);

            if(!numeroObj) {
                return false;
            }

            id = numeroObj.slug;

        } else {
            numeroObj = numero;
            id = numeroObj.slug;
        }

        return {
            numeroObj,
            id
        }

    }

    render() {
        let that = this;
        const { numero } = this.props;
        let numeroObj;
        let id;
        let resetCache = false;

        /*return (
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }} fetchPolicy="cache-first">
                {({loading, error, data, updateQuery, client, networkStatus}) => {
                    return <NumeroSwitch slug={ this.props.match.params.id } client={ client }>
                        <ComponentExemple/>
                    </NumeroSwitch>;
                }}
            </Query>
        );*/

        //return <ComponentExemple />;

        return (
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }} fetchPolicy="cache-first">
                {({loading, error, data, updateQuery, client, networkStatus, refetch}) => {

                    if (loading) return "Loading NUMERO GET ALL...";
                    if (error) return `Error! ${error.message}`;

                    const numeros = data.datas.numeros;


                    if(numeros) {
                        let numeroCache = that.getNumeroDatas(numeros, numero, client);

                        if(!numeroCache){

                            return (
                                <Query query={ GET_NUMERO_BY_SLUG } variables={{
                                    magazine_slug: this.context.magazine.slug,
                                    slug: this.props.match.params.id,
                                }} fetchPolicy="network-only">
                                    {({loading, error, data, updateQuery, client, networkStatus}) => {

                                        if(!data.getNumeroBySlug || !data.getNumeroBySlug._id) {
                                            this.props.history.push("/");
                                            return null;
                                        }

                                        return [
                                            (!loading) ? <CurrentNumero key={ 1 } numeros={ numeros } currentNumero={ data.getNumeroBySlug } client={client} datas={ data.datas } magazine_slug={ this.context.magazine.slug } refetch={ refetch }/> : null,
                                            (!loading) ? <NumeroView match={this.props.match} key={ data.getNumeroBySlug._id }
                                                        id={ data.getNumeroBySlug._id } magazine={this.context.magazine}
                                                        currentNumero={ data.getNumeroBySlug }
                                                        numero={numeros}
                                                        toggleMenu={this.props.toggleMenu}
                                                        menuShow={this.props.menuShow}
                                            /> : null,
                                            (loading || this.state.loading) ? <div className="loading-numero" key={ 3 }>
                                                <div className="logo" />
                                            </div> : null
                                        ];

                                    }}
                                </Query>
                            );
                        } else {
                            let numeroObj = numeroCache.numeroObj; let id = numeroCache.id;

                            // return null;

                            /*if(this.props.loadingView) return(
                                <div className="loading-numero">
                                    <div className="logo" />
                                </div>
                            );*/

                            return [
                                <CurrentNumero key={ 4 } numeros={ numeros } currentNumero={ numeroObj } client={client} datas={ data.datas } magazine_slug={ this.context.magazine.slug } refetch={ refetch }/>,
                                <NumeroView match={this.props.match} key={ id }
                                            id={id} magazine={this.context.magazine}
                                            currentNumero={ numeroObj }
                                            numero={numeros}
                                            toggleMenu={this.props.toggleMenu}
                                            menuShow={this.props.menuShow}
                                />,
                                (this.state.loading) ? <div className="loading-numero" key={ 6 }>
                                    <div className="logo" />
                                </div> : null
                            ];
                        }

                    } return null;

                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        loadingView: state.loadingView.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureMenuClick: (value) => {
            dispatch({
                type: "CLICK_MENU",
                value: value
            })
        },
        toggleLoadingOff: (value) => {
            dispatch({
                type: "LOADING_VIEW",
                loading: false,
            })
        }
    };
};

Numero.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

// export default connect(mapStateToProps, mapDispatchToProps)(Numero);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Numero)));

import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";

class FrameView extends Component {

    render() {

        const { children, height, magazine, isArticle, numero, fonts, logo } = this.props;

        const classes = classNames({
            'magazine-title': isArticle,
            'title': true
        });

        const classes2 = classNames({
            'frame': true
        });

        return(
            <div className={ classes2 } style={{ height }}>
                {children}
                <div className={ classes } onClick={ this.props.captureLogoClick }>
                </div>
            </div>
        )
    }

}


FrameView.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureLogoClick: (value) => {
            dispatch({
                type: "CLICK_LOGO"
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(FrameView)));

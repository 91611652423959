import { Component } from "react";
import * as React from "react";
import classNames from 'classnames';

let ChiffreSlider;

class Chiffres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      heightSlides: 0,
    };
  }

  componentDidMount() {
    let self = this;
    import("react-slick").then((module) => {
      ChiffreSlider = module.default;
      this.setState({
        isLoaded: true,
      });
    });
  }

  render() {
    let self = this;
    let { fonts, datas } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 200,
      fade: true,
      //adaptiveHeight:true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    let article = this.props.article;

    if(!datas.chiffres) {
      return null;
    }

    const color = article.couleur || (article.category || {}).couleur;

    const style_button = {
      backgroundColor: color
    };

    const style = {
      borderColor: color
    };

    //    style.backgroundColor = article.category.couleur;

    /*if(self.state.heightSlides > 0) {
            style.height = self.state.heightSlides;
        }*/

    if (this.state.isLoaded) {
      const has_icon = !!(datas.chiffres[0] || {}).icon;

      const gclasses = classNames({
        'component component-chiffres': true,
        'has-icon': has_icon
      });


      return (
        <div className={gclasses}>
          {datas.chiffres.length > 1 && (
            <button
              className="prev-slider"
              style={style_button}
              onClick={(e) => this.slider.slickPrev()}
            >
              <i className="ico-cr-arrow-left" />
            </button>
          )}
          <ChiffreSlider {...settings} ref={(slider) => (this.slider = slider)}>
            {datas.chiffres.map((chiffre, index) => { 

              const classes = classNames({
                'inner': true,
                'inner-icon': !!chiffre.icon
              });

              return (
                <div className="content-chiffre" key={index}>
                  <div className={classes} style={style}>
                    {chiffre.icon && <span className="icon"><img src={ chiffre.icon } alt="" /></span>}
                    <div className="chiffre" style={fonts.family1}>
                      {chiffre.chiffre}
                    </div>
                    <div className="legende" style={fonts.family3}>
                      {chiffre.legende}
                    </div>
                  </div>
                </div>
              );
            })}
          </ChiffreSlider>
          {datas.chiffres.length > 1 && (
            <button
              className="next-slider"
              style={style_button}
              onClick={(e) => this.slider.slickNext()}
            >
              <i className="ico-cr-arrow-right" />
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className="component component-chiffres">
          {datas.chiffres.map((chiffre, index) => (
            <div className="content-chiffre" key={index} style={{ width: 100 }}>
              <div className="chiffre" style={fonts.family1}>
                {chiffre.chiffre}
              </div>
              <div className="legende" style={fonts.family3}>
                {chiffre.legende}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }
}

export default Chiffres;

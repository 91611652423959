import * as React from "react";
import classNames from 'classnames';

const Citation = ({ fonts, datas, article }) => {
    const color = article.couleur || (article.category || {}).couleur;
    const style = {
        borderColor: color
    };

    const classes = classNames({
      'component component-citation citation': true,
      'citation-picture': !!datas.picture
    });

    return(
        <div className={classes} style={style}>
            {datas.picture && <span className="picture"><img src={ datas.picture } alt="" /></span>}
            <q style={ fonts.family4 } dangerouslySetInnerHTML={{ __html: datas.citation }} />
            {datas.author && <span className="author" style={ fonts.family4 }>{ datas.author }</span>}
        </div>
    )
};

export default Citation;

import React, { PureComponent } from 'react';
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import classNames from "classnames";
import { SettingsContext } from "../../../Magazine/MagazineSettings";
import { ArticleContentView } from "../Chapitre/ArticleContentView";
import Metas from "../Metas/Metas";
import Header from "../Article/components/Header";

class Content extends PureComponent {

    render() {

        let { article, articleDetail,
            match, display, articles, categories, allArticles, magazine,
            numero, toggleMenu, type } = this.props;

        const classesArticle = classNames({
            'breves': (article.custom) ? article.custom.is_breves: false,
            'article': true,
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (

                    <article className={ classesArticle }>
                        <Metas article={ articleDetail } articleDetails={ article } magazine={ magazine } />
                        <Header articles={allArticles} article={ article } fonts={ fonts } numero={ numero }/>
                        <ArticleContentView
                            article={ article }
                            numero={ numero } fonts={ fonts }
                            match={ match }
                        />
                    </article>
                )}
            </SettingsContext.Consumer>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Content)));

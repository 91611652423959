import classNames from "classnames";
import {GET_CURRENT_NUMERO, GET_MENU_NUMEROS} from "../../../../../../Queries";
import { Component } from "react";
import PropTypes from "prop-types";
import {Query} from "react-apollo";
import * as React from "react";
import { SettingsContext } from "../../../../../Magazine/MagazineSettings";

export class NumeroMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            menuShow: false,
        }

    }

    toggleNumeroMenu = (toggle = null, numero = null) => {

        if(toggle === false)
            this.setState(() => {
                return {
                    menuShow: false
                }
            });
        else
            this.setState((prevState) => {
                return {
                    menuShow: !prevState.menuShow
                }
            })
    };

    fetchMore(){

        this.props.fetchMore({
            variables: {
                offset: this.props.numeros.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {

                if (!fetchMoreResult) return prev;

                return Object.assign({}, prev, {
                    numeros: [...prev.numeros, ...fetchMoreResult.numeros]
                });

            }
        });


    }

    handleChange = (numeros, event)  => {

        let numero = numeros.filter((numero) => numero._id === event.target.value)[0];
        this.props.toggleMenu(false, numero)

    };

    render() {

        let length = this.props.numeros.length;

        const classes = classNames({
            'menu-numero': true,
            'menuShow': this.state.menuShow
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <Query query={ GET_MENU_NUMEROS } variables={{
                        magazine_slug: this.context.magazine.slug,
                    }} fetchPolicy="cache-only">
                        {({loading, error, data, updateQuery, client, networkStatus}) => {
                            let numeros = data.datas.menuNumeros;

                            const classes2 = classNames({
                                'numeros-menu': true,
                                'show-more': (numeros.length > 3)
                            });

                            return(
                                <Query query={ GET_CURRENT_NUMERO }>
                                    {({loading, error, data, client}) => {

                                        let current = data.currentNumero;

                                        return(
                                            <div className={ classes }>
                                                {/*<button className="menu-numeros-btn"
                                                        style={ fonts.family2 } onClick={ this.toggleNumeroMenu }>Tous les numéros  &#x25BE;</button>
*/}
                                                <div className="numeros-menu-outer" style={ fonts.family1 }>
                                                    <select value={ current._id } onChange={(e) => this.handleChange(numeros, e) }>
                                                        {numeros.map((numero) => (
                                                            <option value={ numero._id } key={ numero._id }>{ numero.title }</option>
                                                        ))}
                                                    </select>
                                                    <i className="ico-cr-chevron_down" />
                                                    {/*<ul className={ classes2 }>*/}
                                                        {/*{numeros.map((numero) => (*/}
                                                            {/*<li key={numero._id}>*/}
                                                                {/*<Link to={`/${numero.slug}`} onClick={() => { this.props.toggleMenu(false, numero) } }>*/}
                                                                    {/*{numero.title}*/}
                                                                {/*</Link>*/}
                                                            {/*</li>*/}
                                                        {/*))}*/}
                                                    {/*</ul>*/}
                                                </div>
                                            </div>
                                        );

                                    }}
                                </Query>
                            );
                        }}
                    </Query>
                )}
            </SettingsContext.Consumer>
        )
    }



}

NumeroMenu.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

import * as React from "react";

const Title = ({ fonts, datas, article }) => {

    const style = {
      color: article.couleur || article.category && article.category.couleur,
      ...fonts.family2
    };

    if(datas.level === 1)
        return(
            <h2 className="component component-title" style={ style } dangerouslySetInnerHTML={{ __html: datas.text }} />
        );

    if(datas.level === 2)
        return(
            <h3 className="component component-title" style={ style } dangerouslySetInnerHTML={{ __html: datas.text }} />
        )
};

export default Title;

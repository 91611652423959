import { PureComponent } from "react";
import * as React from "react";
import Loadable from "react-loadable";

const Loading = () => null;

const SocialContainer = Loadable({
    loader: () => import('./views/SocialView'),
    loading: Loading
});

export default class SocialsView extends PureComponent {

    render() {

        let { socials } = this.props;

        return (
            <div className="socials">
                <nav>
                    {socials &&
                    <ul data-text="true">
                        {socials.map((social, index) => (
                            <SocialContainer data={ social } key={index} />
                        ))}
                    </ul>
                    }
                </nav>
            </div>
        )
    }
}

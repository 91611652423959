import { Component } from "react";
import * as React from "react";

let Lightbox;

class Image extends Component {

    constructor(props) {

        super(props);

        this.state = {
            images: this.props.datas.images,
            photoIndex: 0,
            isOpen: false,
            isLight: false,
        }
    }

    openLightBox = () => {
        this.setState({
            isOpen: true
        });
    };

    componentDidMount() {
        import('react-image-lightbox').then((module) => {
            Lightbox = module.default;
            this.setState({
                isLight: true
            });
        });
    }

    render(){ const { isOpen, photoIndex, images, isLight } = this.state;
    
    if(!images[photoIndex] || !images[photoIndex].url) {
      return null;
    }

        return(
            <figure className="component component-image">
                <img src={ images[photoIndex].url_full }
                     alt={ images[photoIndex].legend }
                     width="100%"
                     onClick={ this.openLightBox }
                />

                {isOpen && isLight && (
                    <Lightbox
                        mainSrc={images[photoIndex].url_full }
                        nextSrc={images[(photoIndex + 1) % images.length].url_full}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].url_full }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        imageTitle={ images[photoIndex].title }
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
                {images[photoIndex].title &&
                    <figcaption style={ this.props.fonts.family4 }>{ images[photoIndex].title }</figcaption>
                }
            </figure>
        )
    }
}

export default Image;

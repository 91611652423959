import React from "react";

let isRead = (user, article) => {
    if(user.articles) {
        return user.articles.some((articleObj) => {
            return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
        });
    } return false;
};

let calculCircle = (articles, user, min, max) => {

    let self = this;

    if(articles) {

        let nbrArticles = articles.length;

        let nbrReaded = articles.reduce((acc, val) => {
            return isRead(user, val) === false ? acc : acc + 1;
        }, 0);

        let diff = nbrReaded / nbrArticles;
        let differentiel = min - max;

        return min - (diff * differentiel);
    }

    return min;

};

export class SvgCircleOuter extends React.PureComponent {
    render() {

        const { articles, user, category, min, max } = this.props;

        const style = {
            "strokeDashoffset": calculCircle(articles, user, min, max),
            "transition": "all 1s ease",
            "stroke": (category || {}).couleur
        };

        return (
            <SvgCircle category={ category } style={ style } datas={ this.props.datas }/>
        )
    }
}

export class SvgCircle extends React.PureComponent {
    render() {
        const { className, category } = this.props;

        let height = 30, width = 30;

        if(this.props.datas) {
            if (this.props.datas.height) {
                height = this.props.datas.height;
            }

            if (this.props.datas.width) {
                width = this.props.datas.width;
            }
        }

        let icon = '';
        let transform = '';
        let number = (category || {}).number;
        const number_icon = ((category || {}).custom || {}).number_icon;
    
        switch(number_icon) {
          case 'icon_default':
            number = '';
            transform = 'translate(12.5 12) scale(0.01 0.01)';
            icon = 'M-312.889-256l0 853.333c0 93.581 77.086 170.667 170.667 170.667l796.444 0c93.581 0 170.667-77.086 170.667-170.667l0-625.778-113.778 0 0 625.778c0 32.144-24.745 56.889-56.889 56.889s-56.889-24.745-56.889-56.889l0-853.333-910.222 0zM-199.111-142.222l682.667 0 0 739.556c0 22.093 15.871 37.432 23.667 56.889l-649.444 0c-32.144 0-56.889-24.745-56.889-56.889l0-739.556zM-85.333-28.444l0 170.667 455.111 0 0-170.667-455.111 0zM-85.333 256l0 113.778 455.111 0 0-113.778-455.111 0zM-85.333 483.556l0 113.778 455.111 0 0-113.778-455.111 0z';
        }
    
        return (
            <svg className="category-circle" height={ height } width={ width } viewBox="0 0 32 32">
                <g>
                    <circle cx="15" cy="15" r="13" style={ this.props.style } />
                    <circle cx="10" cy="10" r="10" style={ { fill: (category || {}).couleur } } />
                    <path transform={transform} fill="#fff" d={icon} />
                    <text x="50%" y="50%" textAnchor="middle" fill="#fff" dominantBaseline="center">
                        { number }
                    </text>
                </g>
            </svg>
        )
    }
}

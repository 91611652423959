import classNames from "classnames";
import * as React from "react";

import Carrousel from "./Carrousel";
import Image from "./Image";
import Chiffres from "./Chiffres";
import Text from "./Text";
import Title from "./Title";
import Citation from "./Citation";
import Testimonial from "./Testimonial";
import { hexToLuma } from "../../../../../utils";

const generateKey = (kind) => {
    return `${ kind }_${ new Date().getTime() }`;
};

function subChildElements(dynamic, fonts, article, updateMasonry) {

    if(dynamic) {

        return dynamic.map((content, index) => {
            switch (content.kind) {
                case 'title':
                    return (<Title key={'key-c-' + index}  datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id)  } article={ article } />);
                case 'text':
                    return (<Text key={'key-c-' + index}  datas={ content }
                                  fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'citation':
                    return (<Citation key={'key-c-' + index}  datas={ content }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'testimonial':
                    return (<Testimonial key={'key-c-' + index}  datas={ content }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'slider':
                    return (<Carrousel key={'key-c-' + index}  datas={ content } updateMasonry={ updateMasonry }
                                       fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'images':
                    return (<Image key={'key-c-' + index}  datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'chiffres':
                    return (<Chiffres key={'key-c-' + index}  datas={ content } updateMasonry={ updateMasonry }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                default:
                    return null;
            }
        })
    }

    return null;

}

const Encadre = ({ style, fonts, datas, article, updateMasonry }) => {

    const couleur = datas.couleur || article.category.couleur;

    const classes = classNames({
        'component': true,
        'component-encadre': true,
        'dark': couleur ? hexToLuma(couleur) <= 150 : false
    });

    const style_encadre = {
      backgroundColor: couleur
    }
    
    return(
        <div className={ classes } style={ style_encadre }>
            <div className="inner">
                {datas.content.titre &&
                    <h2 style={fonts.family1}>{datas.content.titre}</h2>
                }
                {datas.content.texte &&
                    <p style={fonts.family3} dangerouslySetInnerHTML={{ __html: datas.content.texte }} />
                }
                { subChildElements(datas.content.dynamic, fonts, article, updateMasonry) }
            </div>
        </div>
    )
};

export default Encadre;

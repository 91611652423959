import React, { PureComponent } from 'react';

import HeaderView from "./views/HeaderView";
import {connect} from "react-redux";
import { Query } from "react-apollo";
import {GET_ALL, GET_CURRENT_NUMERO, GET_MENU} from "../../../../../Queries";
import PropTypes from "prop-types";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

class Header extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            scrolled: false,
            scrollFX: false,
        };

    }

    openLinkAndLoading = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.history.push(link);
    };

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.captureLogoClick();
        this.props.history.push(link);
    };

    render() {

        return(
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {
                    return( <Query query={ GET_ALL } variables={
                        {
                            magazine_slug: this.context.magazine.slug,
                            offset: 0
                        }
                    } fetchPolicy="cache-first">
                        {({loading, error, data, fetchMore}) => {

                            let datas = data.datas;

                            return (
                                <Query query={ GET_MENU }>
                                    {({loading, error, data, client}) => {
                                        let menu = data.getMenu;

                                        return (
                                            <Query query={ GET_CURRENT_NUMERO }>
                                                {({loading, error, data, client}) => {

                                                    if (!data.currentNumero) {
                                                        this.forceUpdate();
                                                    }

                                                    if (loading) return "Loading GET_CURRENT_NUMERO header...";
                                                    if (error) return `Error! ${error.message}`;

                                                    return (
                                                        <HeaderView match={this.props.match}
                                                                    toggleMenu={this.props.toggleMenu} data={datas}
                                                                    menu={ menu }
                                                                    fixTimelineBar={ this.props.fixTimelineBar }
                                                                    scrollDirection={ this.props.scrollDirection }
                                                                    isNoImg={ this.props.isNoImg }
                                                                    setMenuInner={ this.props.setMenuInner }
                                                                    menuInnerShow={ this.props.menuInnerShow }
                                                                    menuShow={ this.props.menuShow }
                                                                    openLinkAndLoading={ this.openLinkAndLoading }
                                                                    currentArticles={ this.props.currentArticles }
                                                                    isPage={ this.props.isPage }
                                                                    isSearching={ this.props.search }
                                                                    openLink={ this.openLink }
                                                                    setMenuNumero={ this.props.setMenuNumero }
                                                                    scrollFX={this.state.scrollFX}
                                                                    user={ this.props.user }
                                                                    fetchMore={fetchMore}
                                                                    client={ this.props.client }
                                                                    menuNumero={ this.props.menuNumero }
                                                                    magazine={this.context.magazine}
                                                                    currentNumero={data.currentNumero}
                                                                    updateWidthScroll={ this.props.updateWidthScroll }
                                                                    logo={ logo }
                                                                    kindView={ this.props.view }
                                                                    fonts={ fonts }
                                                        />
                                                    )

                                                }}
                                            </Query>
                                        );

                                    }}
                                </Query>
                            );
                        }}
                    </Query> )
                }}
            </SettingsContext.Consumer>
        )
    }


}

const mapStateToProps = (state, props) => {
    return {
        slug: props.match.params.slug,
        scrolled: state.scrolled.scrolled,
        scrollDirection: state.scrolled.scrollDirection,
        currentArticles: state.user.lastArticlesRead,
        menuNumero: state.menuNumero.active,
        menuInnerShow: state.menu.menuInnerShow,
        menuShow: state.menu.menuShow,
        fixTimelineBar: state.scrolled.fixTimelineBar,
        search: state.search.isSearching,
        view: state.view.kind,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        headerScrolled: (value) => {
            dispatch({
                type: "CHANGE_SCROLL",
                value: value
            })
        },
        updateWidthScroll: (value) => {
            dispatch({
                type: "UPDATE_WIDTH_SCROLL",
                value: value
            })
        },
        captureLogoClick: (value) => {
            dispatch({
                type: "CLICK_LOGO"
            })
        },
        isNoImg: (value) => {
            dispatch({
                type: "SET_NO_IMG",
                value
            })
        },
        toggleLoadingOn: () => {
            dispatch({
                type: "LOADING_VIEW_MAIN",
                loading: true
            })
        },
        setMenuInner: (value) => {
            dispatch({
                type: "TOGGLE_MENU_INNER_VALUE",
                value
            })
        },
        setMenuNumero: (value) => {
            dispatch({
                type: "SET_MENU_NUMERO",
                value
            })
        }
    };
};

Header.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Header)));

import React, { Component } from 'react';
import { connect } from "react-redux";
import { GET_CURRENT_NUMERO } from "../../../../../Queries";
import { Query } from "react-apollo";
import classNames from 'classnames';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { ScrollContext } from "../../../../Scroll/Scroll";
import {ArticlesListingSubcategory} from "../../../../components/ArticleListingSubcategory";
import {SvgCircleOuter} from "../../Chapitre/Circle";

function truncate(string, length){
    if (string.length > length)
        return string.substring(0, length) + '...';
    else
        return string;
}

function renderTitle(title) {

    if(typeof window !== "undefined") {
        return truncate(title, 54)
    } else {
        return truncate(title, Infinity)
    }

}

class BlockChapitre extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            scrolled: false
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.isCurrent = this.isCurrent.bind(this);

    }

    findArticlesToChapitre(articles) {
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    isCurrent(article) {
        return (article.slug === this.props.match.params.slug)
    }

    isCurrentChapitre(chapitre){
        return (chapitre.slug === this.props.match.params.chapitre);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll() {
        if( window.scrollY >= (539 - 90)){
            if(!this.state.scrolled)
                this.setState({ fixed: true, scrolled: true })
        } else {
            if(this.state.scrolled)
                this.setState({ fixed: false, scrolled: false })
        }
    }

    render() {

        return(
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {
                    const magazine = this.context.magazine;
                    const articles = data.currentNumero.articles;

                    let articlesInChapitre = this.findArticlesToChapitre(articles);

                    if(articlesInChapitre)
                        return (
                            <ScrollContext.Consumer>
                                {({ scrollWidth, scrollValue }) => (
                                    <BlockChapitreView
                                        articles={ articlesInChapitre }
                                        numero={ data.currentNumero }
                                        isCurrentChapitre={ this.isCurrentChapitre }
                                        user={ this.props.user }
                                        scrollValue={ scrollValue }
                                        match={ this.props.match }
                                        scrollWidth={ scrollWidth }
                                        fixed={ this.state.fixed }
                                        isCurrent={ this.isCurrent }
                                        fonts={ this.props.fonts }
                                    />
                                )}
                            </ScrollContext.Consumer>
                        );
                    else return null;

                }}
            </Query>
        );

    }

}

const mapStateToProps = (state, props) => {
    return {
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

BlockChapitre.contextTypes = {
    magazine: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockChapitre));

const isRead = (user, article) => {
    if(user.articles) {
        return user.articles.some((articleObj) => {
            return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
            //return (articleObj._id === article._id && articleObj.readed);
        });
    } return false;
};

class BlockChapitreView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
            scrolled: false,
            display: true,
            top: "initial",
            height: "auto",
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleScroll();
        this.handleResize();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize, false);
    }
    componentDidUpdate() {
        this.handleScroll();
    }

    handleScroll() {

        if(document.getElementsByClassName("articleHeader").length > 0) {
            let heightFrame = document.getElementsByClassName("articleHeader")[0].getBoundingClientRect().height;
            let heightHeader = document.querySelector("header.header").getBoundingClientRect().height;
            let playerHeight = 0;
            let titleChapitreHeight = 0;
            let paddingTopChapitre = "0px";

            if (document.querySelector(".block-chapitre")) {
                titleChapitreHeight =
                    document.querySelector(".inner-chapitre > header").getBoundingClientRect().height;
                paddingTopChapitre = window.getComputedStyle(document.querySelector(".inner-chapitre"), null).getPropertyValue("padding-top");
                playerHeight = document.querySelector(".player-outer").getBoundingClientRect().height;
            }

            if (this.props.scrollValue >= heightFrame + heightHeader) {
                if (!this.state.scrolled) {
                    this.setState(
                        {
                            fixed: true,
                            scrolled: true,
                            top:  "0px",
                            height: (window.innerHeight - heightHeader - titleChapitreHeight - playerHeight - parseInt(paddingTopChapitre, 10)) + "px"
                        })
                }
            } else {
                if (this.state.scrolled)
                    this.setState(
                        {
                            fixed: false,
                            scrolled: false,
                            top: "0px",
                            height: "auto"
                        })
            }
        }

    }

    getCurrentArticle() {
        return this.props.articles.find((article) => article.slug === this.props.match.params.slug)
    }

    haveCurrentArticleSubcategory() {
        let current = this.getCurrentArticle();
        if(current) {
            return current.custom.sous_chapitre
        } else {
            return false;
        }
    }

    handleResize() {

        if(this.props.articles.length === 0) return null;

        if(this.displayBlockChapitreEnoughSpace()){
            if(!this.state.display)
                this.setState({
                    display: true
                })
        } else {
            if(this.state.display)
                this.setState({
                    display: false
                })
        }
    }

    displayBlockChapitreEnoughSpace() {

        if(document.querySelector(".global-content")) {
            let widthInnerMain = document.querySelector(".inner-main").getBoundingClientRect().width;
            let widthContent = document.querySelector(".global-content").getBoundingClientRect().width;
            let widthChapitreBloc = document.querySelector(".inner-chapitre").getBoundingClientRect().width;
            let diff = (widthInnerMain - widthContent) / 2;
            return diff >= widthChapitreBloc;
        } return false;
    }

    findArticlesToChapitre(articles) {
      return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    render() {

        const { articles, numero, fixed, isCurrent, fonts, user, scrollWidth, match } = this.props;

        if(articles.length === 0) return null;

        const classes = classNames({
            'fixed': this.state.fixed,
            'display': this.state.display,
            'inner-chapitre': true,
        });

        let hasSubCat = this.haveCurrentArticleSubcategory();

        return(
            <section className="block-chapitre">
                <div className={ classes } style={ { top: this.state.top } }>
                    <header>
                        <h1 data-text="true" style={ fonts.family1 }>Progression dans le chapitre</h1>
                    </header>
                    <nav style={ fonts.family3 }>
                        <ul style={ { height: this.state.height } }>

                            <ArticlesListingSubcategory {...this.props} isRead={ this.isRead }>
                                {(allArticles, articlesNoSub, sousChapitresArr) => {

                                    if(!sousChapitresArr)
                                        return allArticles.map((article, index) =>
                                            <ArticleRender {...this.props} article={ article } key= {index }
                                                           index={ index } isRead={ isRead }/>
                                        );
                                    else {
                                        return[
                                            (!hasSubCat) ? articlesNoSub.before.map((article, index) =>
                                                <ArticleRender article={ article }
                                                               index={ index }
                                                               key= {index }
                                                               {...this.props}
                                                               isRead={ isRead }
                                                />
                                            ) : null,
                                            (hasSubCat) ? <div className="subcategories" key={ 1 }>
                                                {sousChapitresArr.map((sous_chapitre, index) => {
                                                    let articlesSub = allArticles
                                                        .filter((article) =>
                                                            article.custom.sous_chapitre &&
                                                            article.custom.sous_chapitre.slug === sous_chapitre.slug);

                                                    let currentArticle = articlesSub.find((article) =>
                                                        article.slug === match.params.slug);

                                                    if(!currentArticle)
                                                        return null;
                                                    if(currentArticle.custom.sous_chapitre.slug !== sous_chapitre.slug)
                                                        return null;

                                                    return(
                                                        <div className="subcategory" key={ sous_chapitre.id }>
                                                            <span className="subcategory-title"
                                                                  style={ fonts.family1 }>
                                                                {index+1}. { sous_chapitre.title }
                                                                </span>
                                                            {articlesSub && articlesSub.map((article, index) =>
                                                                <ArticleRender article={ article }
                                                                               key= {index }
                                                                               isRead={ isRead }
                                                                               index={ index }
                                                                               {...this.props}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div> : null,
                                            (!hasSubCat) ? articlesNoSub.after.map((article, index) =>
                                                <ArticleRender article={ article }
                                                               index={ index }
                                                               key= {index }
                                                               {...this.props}
                                                               isRead={ isRead }
                                                />
                                            ) : null
                                        ]
                                    }
                                }}
                            </ArticlesListingSubcategory>

                        </ul>
                    </nav>
                </div>
            </section>
        )

    }
}

let ArticleRender = ({ article, index, isRead, isCurrent, user, numero, scrollWidth, fonts }) => {

    const isReadArticle = isRead(user, article);
    const isCurrentArticle = isCurrent(article);

    const classes = classNames({
        'current': isCurrentArticle,
        'readed': isReadArticle
    });


    const style = {
      borderColor: (isCurrentArticle || isReadArticle) ? article.category.couleur : '#00000033',
      borderWidth: (isReadArticle || isReadArticle) ? 2 : null,
      boxShadow: isCurrentArticle ? '0 0 32px' + article.category.couleur + '7D' : null,
      backgroundColor: (isReadArticle && !isCurrentArticle) ? article.category.couleur + '1A' : null
    };

    return(
        <li key={index} className={ classes } data-text="true" style={style}>
            <Link to={`/${numero.slug}/${article.category.slug}/${article.slug}`} style={ fonts.family3 }>
                { isCurrentArticle &&
                <ScrollVisualizer scrollWidth={ scrollWidth } color={article.category.couleur + '1A'} />
                }
                <span>{renderTitle(article.title)}</span>
            </Link>
        </li>
    );

};

class ScrollVisualizer extends Component {

    render() {

        const style = {
            backgroundColor: this.props.color,
            transform: "scaleX(" + this.props.scrollWidth/100+ ")"
        };

        return(
            <div className="scroll-visualiser" style={ style }  ref={ this.ref } />
        )
    }

}

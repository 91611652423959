import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SettingsContext } from "../../../Magazine/MagazineSettings";
import { MenuNumeroView } from "./views/MenuNumeroView";

class MenuNumero extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <Query query={ GET_ALL } variables={
                {
                    magazine_slug: this.context.magazine.slug,
                    offset: 0
                }
            } fetchPolicy="cache-first">
                {({ loading, error, data, fetchMore }) => {
                    let datas = data.datas;

                    return(
                        <SettingsContext.Consumer>
                            {({ logo }) => {
                                return (
                                    <Query query={GET_CURRENT_NUMERO}>
                                        {({loading, error, data, client}) => {
                                            return (
                                                <MenuNumeroView
                                                          numero={ data.currentNumero }
                                                          user={ this.props.user }
                                                          match={ this.props.match }
                                                          history={ this.props.history }
                                                          setMenuNumero={ this.props.setMenuNumero }
                                                          menuNumeroActive={this.props.menuNumeroActive}
                                                          menu={ this.props.menu }
                                                          toggleMenu={ this.props.toggleMenu }
                                                          logo={ logo }
                                                          datas={ datas }
                                                          captureContactClick={ this.props.captureContactClick }
                                                          openRGPDPopup={ this.props.openRGPDPopup }
                                                />
                                            )
                                        }}
                                    </Query>
                                )
                            }}
                        </SettingsContext.Consumer>
                    )

                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        magazine: state.magazine,
        user: state.user,
        menu: state.menuNumero.active
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openRGPDPopup: () => {
            dispatch({
                type: "OPEN_WINDOW_RGPD",
                value: true
            })
        },
        captureContactClick: () => {
            dispatch({
                type: "CLICK_CONTACT"
            })
        },
        setMenuNumero: (value) => {
            dispatch({
                type: "SET_MENU_NUMERO",
                value
            })
        }
    };
};

MenuNumero.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuNumero));
